import React from 'react';
import { Link } from 'gatsby';


export const Hero3 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Admin Dashboards templates
            </h2>
            <p className="feature__text mb-5">
              Contrast comes with admin dashboards and pages template which you can easily use to get
               started on your admin or web app projects.
            </p>
            <a target="_blank" href="https://contrast-bootstrap-admin.netlify.app/" className="btn1 btn__inverse" rel="noreferrer">
              View dashboard
            </a>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/adminheropro.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
