import React from 'react';
import { Link } from 'gatsby';
import Showcase from '../../Showcase/Showcase.js';
import cardImage from '../../images/cardbg.png';

import styled from "../../../components/images/styledc.png";

export const Hero2 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/adminfeatfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Robust UI components
            </h2>
            <p className="feature__text mb-5">
              Contrast has over 2000+ varaints of its UI components which have also been well documented to show the different ways they can be combined.
            </p>
            <a target="_blank" href="https://contrast-bootstrap-admin.netlify.app/" className="btn1 btn__inverse" rel="noreferrer">
              View components
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
