import React from 'react';
import Helmet from 'react-helmet';

import { Hero } from '../../components/AdminAbout/Bootstrap/Hero';
// import { Feature } from '../../components/AdminAbout/Bootstrap/Feature';
// import { Showcase } from '../../components/AdminAbout/Bootstrap/Showcase';
// import { About as AboutSection } from '../../components/AdminAbout/Bootstrap/About';
import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/AdminAbout/Bootstrap/VideoSection';
import { Hero3 } from '../../components/AdminAbout/Bootstrap/Hero3';
import { Hero2 } from '../../components/AdminAbout/Bootstrap/Hero2';
import { Hero4 } from '../../components/AdminAbout/Bootstrap/Hero4';
import { Feature } from '../../components/AdminAbout/Bootstrap/Feature';
import { Showcase } from '../../components/AdminAbout/Bootstrap/Showcase';
import { PagesShowcase } from '../../components/AdminAbout/Bootstrap/PagesShowcase';
import { DocsPreview } from '../../components/AdminAbout/Bootstrap/DocsPreview';
import { Comparison } from '../../components/AdminAbout/Bootstrap/Comparison';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import '../../styles/scss/style.scss'

const AdminAbout = () => {

  return (
    <AppContext>

      <Helmet>
        <title> Contrast Bootstrap 5 UI Kit + Free Multipurpose Admin Template</title>
        <meta name="title" content="Contrast Bootstrap 5 UI Kit + Free Multipurpose Admin Template" />
        <meta
          name="description"
          content="Bootstrap 5 UI kit + Multipurpose Admin template. Beautifully designed responsive pages and admin dashboards built with today's standards and developed with the best tools to build your next landing, admin, SAAS, prelaunch, etc project in record time"
        />
        <meta property="og:title" content="Contrast Bootstrap 5 UI Kit + Free Multipurpose Admin Template" />
        <meta
          property="og:description"
          content="Bootstrap 5 UI kit + Multipurpose Admin template. Beautifully designed responsive pages and admin dashboards built with today's standards and developed with the best tools to build your next landing, admin, SAAS, prelaunch, etc project in record time"
        />
        <meta property="twitter:title" content="Contrast Bootstrap 5 UI Kit + Free Multipurpose Admin Template" />
        <meta
          property="twitter:description"
          content="Bootstrap 5 UI kit + Multipurpose Admin template. Beautifully designed responsive pages and admin dashboards built with today's standards and developed with the best tools to build your next landing, admin, SAAS, prelaunch, etc project in record time"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <VideoSection />
            <div className="mt-5">
              <FeaturedIn />
            </div>
            <Feature />
            <Hero3 />
            <Hero2 />
            <Hero4 />
            <PagesShowcase />
            <DocsPreview />
            <Showcase />
            <Comparison />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminAbout;
