import React from 'react';
import { Link } from 'gatsby';
import Showcase from '../../Showcase/Showcase.js';
import cardImage1 from '../../images/dashboard-freemium.png';
import cardImage2 from '../../images/asignin.png';
import cardImage3 from '../../images/acreateaccount.png';
import cardImage4 from '../../images/sign-up-form-1-3.png';
import cardImage5 from '../../images/aforgotpassword.png';
import cardImage6 from '../../images/sign-up-form-1-5.png';
import cardImage7 from '../../images/sign-up-form-1-6.png';
import cardImage8 from '../../images/sign-up-form-1-4.png';
import cardImage9 from '../../images/aprofile.png';
import cardImage10 from '../../images/atable.png';
import cardImage11 from '../../images/apostpage.png';


export const PagesShowcase = () => {
  const options = [
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/dashboard.html',
      src: cardImage1,
      name: 'Dashboard',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/signin3.html',
      src: cardImage2,
      name: 'Sign In Page',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/create-account.html',
      src: cardImage3,
      name: 'Create Account',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/hero4.html',
      src: cardImage4,
      name: 'Hero',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/forgot-password2.html',
      src: cardImage5,
      name: 'Forgot Password',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/hero404.html',
      src: cardImage6,
      name: '404 Page',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/hero5.html',
      src: cardImage7,
      name: 'Hero 5',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/pricing.html',
      src: cardImage8,
      name: 'Pricing',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/profile.html',
      src: cardImage9,
      name: 'Profile Page',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/table.html',
      src: cardImage10,
      name: 'Tables',
    },
    {
      href: 'https://cdbootstrap-free-demo.netlify.app/pages/post.html',
      src: cardImage11,
      name: 'Posts Page',
    },
  ];

  return (
    <div className="container">
      <div className="hero my-5 py-5">
        <h2 className="font-bold text-4xl text-center mb-4">Pages Preview</h2>
        <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
          Some well designed and developed pages that you will find in the Contrast Bootstrap 5 UI
          kit & multipurpose admin template
        </h5>
        <div
          className="mt-5 pt-5"
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '40px' }}
        >
          {options.map((item, index) => (
            <Showcase
              className="shadow"
              key={index}
              name={item.name}
              href={item.href}
              src={item.src}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
