import React from 'react';
import { Link } from 'gatsby';

export const Feature = () => {
  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/aboutlastfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Landing page templates
            </h2>
            <p className="feature__text mb-5">
              Contrast also comes bundled with landing pages and hero section templates to help
              you get started creating landing pages easily for different campaigns and projects
            </p>
            <a target="_blank" href="https://cdbootstrap-free-demo.netlify.app/pages/landing.html" className="btn1 btn__inverse" rel="noreferrer">
              View pages
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
