import React from 'react';
import { Link } from 'gatsby';


export const Hero4 = () => {

  return (
   <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">  
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Bootstrap 5
            </h2>
            <p className="feature__text mb-5">
              Contrast was developed using the latest version of Bootstrap 5 which makes it possible not
              to include jquery, thereby making usage, editing of components and template all done with Vanilla Javascript
            </p>
            <Link to="/docs/contrast/javascript/index" className="btn1 btn__inverse">
              View Documentation
            </Link>
           
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/bootstrapv5newlogo.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
